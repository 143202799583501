import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Meta } from 'styles/article';

const Wrapper = styled(Meta)`
  padding: ${prop('padded', '1rem')};

  .tag {
    color: #8c919b;
    font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica,
      sans-serif;
    font-weight: 400;
    letter-spacing: 0.24rem;
    line-height: 2.2rem;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Tags = ({ tags, padded = false }) => (
  <Wrapper>
    {tags.map((tag, i) => (
      <span key={i}>
        <a href={`/tags/${tag}`} className="tag">
          {tag}
        </a>
        {tags.length > i + 1 && ', '}
      </span>
    ))}
  </Wrapper>
);

export default Tags;
