import styled from 'styled-components';
import { DARK_JUNGLE_GREEN } from 'constants/colors';

export const Meta = styled.span`
  font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica, sans-serif;
  letter-spacing: 0.24rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
`;

export const H1 = styled.h1`
  color: ${DARK_JUNGLE_GREEN} !important;
  font-size: 3rem !important;
  font-family: 'Oswald', Arial, Verdana, 'Helvetica Neue', Helvetica, sans-serif !important;
  flex-direction: column;
  text-align: center;
  padding-top: 0;
`;

export const H2 = styled(H1)`
  font-size: 2rem !important;
  padding-top: 2.5rem;
`;
