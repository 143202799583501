import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import parse from 'date-fns/parse';
import { Meta } from 'styles/article';

const Wrapper = styled(Meta)`
  padding-left: 1rem;
`;

const TimeStamp = ({ date }) => (
  <Wrapper>
    {format(parse(date, 'yyyy-MM-dd', new Date()), 'MMMM dd, yyyy')}
  </Wrapper>
);

export default TimeStamp;
